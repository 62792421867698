export default {
  seoTitle: 'Купить {{- title}}. {{- title}} в Лепеле',
  seoDescription:
    'Выбрать и купить {{- title}} по низким ценам в Лепеле с доставкой по Беларуси. Выгодные цены и широкий ассортимент на {{- title}}. ✔ Официальная гарантия. ✔ Рассрочка',
  pageTitle: 'Каталог товаров',
  resetFilters: 'Сбросить фильтры',
  toolDefault: 'По умолчанию',
  toolSortPriceAsc: 'Сначала подешевле',
  toolSortPriceDesc: 'Сначала подороже',
  toolPageSize: 'Показывать по',
};

import { Reducer } from 'redux';
import { ActionType } from 'typesafe-actions';

import * as actionsCreators from './actions';
import { UiActionTypes, Ui } from './types';

export type UiState = Ui;
export type UiAction = ActionType<typeof actionsCreators>;

export const uiInitialState: UiState = {
  isSidebarOpen: false,
  isUserMenuOpen: false,
  isListingMenuOpen: false,
  isMenuOpen: false,
  catalog: [
    {
      id: 0,
      title: 'Компьютеры и сети',
      system_name: '',
      link: '',
      children: [
        {
          id: 24,
          title: 'Ноутбуки',
          system_name: 'notebook',
          link: '/catalog/notebook/',
        },
        {
          id: 0,
          title: 'Телевизоры, мониторы',
          system_name: '',
          link: '',
          children: [
            {
              id: 354,
              title: 'Телевизоры',
              system_name: 'tv',
              link: '/catalog/tv/',
            },
            {
              id: 134,
              title: 'Мониторы',
              system_name: 'monitor',
              link: '/catalog/monitor/',
            },
          ],
        },
        {
          id: 0,
          title: 'Планшеты, электронные книги',
          system_name: '',
          link: '',
          children: [
            {
              id: 34,
              title: 'Планшеты',
              system_name: 'tablet',
              link: '/catalog/tablet/',
            },
          ],
        },
        {
          id: 0,
          title: 'Печать и дизайн',
          system_name: '',
          link: '',
          children: [
            {
              id: 204,
              title: 'Принтеры',
              system_name: 'printer',
              link: '/catalog/printer/',
            },
            {
              id: 194,
              title: 'МФУ',
              system_name: 'mfd',
              link: '/catalog/mfd/',
            },
            {
              id: 553,
              title: 'Сканеры',
              system_name: 'scanner',
              link: '/catalog/scanner/',
            },
            {
              id: 0,
              title: 'Бумага и материалы для печати',
              system_name: '',
              link: '',
              children: [
                {
                  id: 561,
                  title: 'Бумага',
                  system_name: 'paper',
                  link: '/catalog/paper/',
                },
                {
                  id: 573,
                  title: 'Фотобумага',
                  system_name: 'photo-paper',
                  link: '/catalog/photo-paper/',
                },
                {
                  id: 564,
                  title: 'Чернила',
                  system_name: 'ink',
                  link: '/catalog/ink/',
                },
                {
                  id: 491,
                  title: 'Картриджи',
                  system_name: 'cartridge',
                  link: '/catalog/cartridge/',
                },
                {
                  id: 537,
                  title: 'Ламинаторы',
                  system_name: 'laminator',
                  link: '/catalog/laminator/',
                },
              ],
            },
          ],
        },
        {
          id: 0,
          title: 'Комплектующие',
          system_name: '',
          link: '',
          children: [
            {
              id: 154,
              title: 'Жесткие диски',
              system_name: 'sdd',
              link: '/catalog/hdd/',
            },
            {
              id: 484,
              title: 'SDD',
              system_name: 'sdd',
              link: '/catalog/sdd/',
            },
            {
              id: 566,
              title: 'Приводы',
              system_name: 'disk-drive',
              link: '/catalog/disk-drive/',
            },
          ],
        },
        {
          id: 0,
          title: 'Манипуляторы и устройства ввода',
          system_name: '',
          link: '',
          children: [
            {
              id: 560,
              title: 'Мыши',
              system_name: 'mouse',
              link: '/catalog/mouse/',
            },
            {
              id: 560,
              title: 'Клавиатуры',
              system_name: 'keyboard',
              link: '/catalog/keyboard/',
            },
            {
              id: 587,
              title: 'Наборы (клавиатура + мышь)',
              system_name: 'keyboard-mouse',
              link: '/catalog/keyboard-mouse/',
            },
            {
              id: 569,
              title: 'Коврики для мыши',
              system_name: 'mouse-pad',
              link: '/catalog/mouse-pad/',
            },
          ],
        },
        {
          id: 0,
          title: 'Мультимедиа',
          system_name: '',
          link: '',
          children: [
            {
              id: 527,
              title: 'Акустика',
              system_name: 'acoustics',
              link: '/catalog/acoustics/',
            },
            {
              id: 591,
              title: 'Музыкальные центры',
              system_name: 'music-centre',
              link: '/catalog/music-centre/',
            },
            {
              id: 214,
              title: 'Наушники',
              system_name: 'headphones',
              link: '/catalog/headphones/',
            },
            {
              id: 504,
              title: 'Микрофоны',
              system_name: 'microphone',
              link: '/catalog/microphone/',
            },
          ],
        },
        {
          id: 0,
          title: 'Сетевое оборудование',
          system_name: '',
          link: '',
          children: [
            {
              id: 562,
              title: 'Wi-Fi адаптеры',
              system_name: 'wireless-adapter',
              link: '/catalog/wireless-adapter/',
            },
            {
              id: 565,
              title: 'Wi-Fi точки доступа',
              system_name: 'wireless-access-point',
              link: '/catalog/wireless-access-point/',
            },
            {
              id: 572,
              title: 'Wi-Fi маршрутизаторы',
              system_name: 'wireless-router',
              link: '/catalog/wireless-router/',
            },
            {
              id: 567,
              title: 'Кабели',
              system_name: 'сable',
              link: '/catalog/сable/',
            },
            {
              id: 570,
              title: 'Коммутаторы',
              system_name: 'switch',
              link: '/catalog/switch/',
            },
            {
              id: 574,
              title: 'Сетевые фильтры',
              system_name: 'network-filter',
              link: '/catalog/network-filter/',
            },
            {
              id: 579,
              title: 'Сетевые адапторы',
              system_name: 'network-adapter',
              link: '/catalog/network-adapter/',
            },
            {
              id: 584,
              title: 'Переходники',
              system_name: 'adapter',
              link: '/catalog/adapter/',
            },
            {
              id: 594,
              title: 'USB-хабы',
              system_name: 'usb-hub',
              link: '/catalog/usb-hub/',
            },
          ],
        },
        {
          id: 0,
          title: 'Электропитание',
          system_name: '',
          link: '',
          children: [
            {
              id: 174,
              title: 'ИБП',
              system_name: 'ups',
              link: '/catalog/ups/',
            },
          ],
        },
        {
          id: 0,
          title: 'Аксессуары',
          system_name: '',
          link: '',
          children: [
            {
              id: 575,
              title: 'Салфетки для экранов',
              system_name: 'screen-wipes',
              link: '/catalog/screen-wipes/',
            },
            {
              id: 585,
              title: 'Диски',
              system_name: 'disk',
              link: '/catalog/disk/',
            },
            {
              id: 586,
              title: 'Конверты',
              system_name: 'envelope',
              link: '/catalog/envelope/',
            },
            {
              id: 589,
              title: 'Наклейки на клавиатуру',
              system_name: 'keyboard-sticker',
              link: '/catalog/keyboard-sticker/',
            },
            {
              id: 597,
              title: 'Сумки для ноутбуков',
              system_name: 'notebook-bag',
              link: '/catalog/notebook-bag/',
            },
            {
              id: 596,
              title: 'Рюкзаки',
              system_name: 'backpack',
              link: '/catalog/backpack/',
            },
          ],
        },
      ],
    },
    {
      id: 0,
      title: 'Электроника',
      system_name: '',
      link: '',
      children: [
        {
          id: 0,
          title: 'Гаджеты',
          system_name: '',
          link: '',
          children: [
            {
              id: 544,
              title: 'Геймпады',
              system_name: 'gamepad',
              link: '/catalog/gamepad/',
            },
            {
              id: 508,
              title: 'Умные часы',
              system_name: 'smart-watch',
              link: '/catalog/smart-watch/',
            },
            {
              id: 542,
              title: 'Наручные часы',
              system_name: 'wrist-watch',
              link: '/catalog/wrist-watch/',
            },
            {
              id: 550,
              title: 'Фитнес-браслеты',
              system_name: 'fitness-bracelet',
              link: '/catalog/fitness-bracelet/',
            },
            {
              id: 543,
              title: 'Очки виртуальной реальности',
              system_name: 'virtual-reality-glasses',
              link: '/catalog/virtual-reality-glasses/',
            },
            {
              id: 576,
              title: 'Беспроводные колонки',
              system_name: 'wireless-speaker',
              link: '/catalog/wireless-speaker/',
            },
          ],
        },
        {
          id: 0,
          title: 'Аксессуары',
          system_name: '',
          link: '',
          children: [
            {
              id: 563,
              title: 'USB Flash',
              system_name: 'usb-flash',
              link: '/catalog/usb-flash/',
            },
            {
              id: 583,
              title: 'Карта памяти',
              system_name: 'memory-card',
              link: '/catalog/memory-card/',
            },
            {
              id: 598,
              title: 'Bluetooth гарнитуры',
              system_name: 'bluetooth-headset',
              link: '/catalog/bluetooth-headset/',
            },
          ],
        },
        {
          id: 0,
          title: 'Электропитание',
          system_name: '',
          link: '',
          children: [
            {
              id: 559,
              title: 'Портативные зарядные устройства',
              system_name: 'portable-charger',
              link: '/catalog/portable-charger/',
            },
          ],
        },
      ],
    },
    {
      id: 0,
      title: 'Телефоны',
      system_name: '',
      link: '',
      children: [
        {
          id: 224,
          title: 'Смартфоны',
          system_name: 'smartphone',
          link: '/catalog/smartphone/',
        },
        {
          id: 44,
          title: 'Мобильные телефоны',
          system_name: 'mobile',
          link: '/catalog/mobile/',
        },
        {
          id: 539,
          title: 'Радиотелефоны',
          system_name: 'radio-phone',
          link: '/catalog/radio-phone/',
        },
        {
          id: 538,
          title: 'Проводные телефоны',
          system_name: 'landline-phone',
          link: '/catalog/landline-phone/',
        },
      ],
    },
    {
      id: 0,
      title: 'Бытовая техника',
      system_name: '',
      link: '',
      children: [
        {
          id: 0,
          title: 'Крупногабаритная техника',
          system_name: '',
          link: '',
          children: [
            {
              id: 510,
              title: 'Стиральные машины',
              system_name: 'washing-machine',
              link: '/catalog/washing-machine/',
            },
            {
              id: 521,
              title: 'Пылесосы',
              system_name: 'vacuum-cleaner',
              link: '/catalog/vacuum-cleaner/',
              children: [
                {
                  id: 581,
                  title: 'Комплекты пылесборников',
                  system_name: 'set-of-dust-collectors',
                  link: '/catalog/set-of-dust-collectors/',
                },
              ],
            },
            {
              id: 314,
              title: 'Кухонные вытяжки',
              system_name: 'kitchen-hood',
              link: '/catalog/kitchen-hood/',
            },
            {
              id: 590,
              title: 'Морозильники',
              system_name: 'freezer',
              link: '/catalog/freezer',
            },
          ],
        },
        {
          id: 0,
          title: 'Уборка, уход за одеждой, пошив',
          system_name: '',
          link: '',
          children: [
            {
              id: 496,
              title: 'Утюги',
              system_name: 'iron',
              link: '/catalog/iron/',
            },
            {
              id: 588,
              title: 'Паровые щетки',
              system_name: 'steam-brush',
              link: '/catalog/steam-brush/',
            },
          ],
        },
        {
          id: 0,
          title: 'Приготовление пищи',
          system_name: '',
          link: '',
          children: [
            {
              id: 470,
              title: 'Микроволновые печи',
              system_name: 'microwave',
              link: '/catalog/microwave/',
            },
            {
              id: 314,
              title: 'Мультиварки',
              system_name: 'multicooker',
              link: '/catalog/multicooker/',
            },
            {
              id: 529,
              title: 'Хлебопечки',
              system_name: 'bread-maker',
              link: '/catalog/bread-maker/',
            },
            {
              id: 534,
              title: 'Пароварки',
              system_name: 'catalog/steamer',
              link: '/catalog/steamer/',
            },
            {
              id: 547,
              title: 'Сэндвичницы',
              system_name: 'sandwich-maker',
              link: '/catalog/sandwich-maker/',
            },
          ],
        },
        {
          id: 0,
          title: 'Приготовление кофе и чая',
          system_name: '',
          link: '',
          children: [
            {
              id: 556,
              title: 'Чайники',
              system_name: 'kettle',
              link: '/catalog/kettle/',
            },
            {
              id: 535,
              title: 'Кофеварки',
              system_name: 'coffee-maker',
              link: '/catalog/coffee-maker/',
            },
            {
              id: 551,
              title: 'Кофемолки',
              system_name: 'сoffee-grinder',
              link: '/catalog/сoffee-grinder/',
            },
            {
              id: 536,
              title: 'Турки',
              system_name: 'turka',
              link: '/catalog/turka/',
            },
          ],
        },
        {
          id: 0,
          title: 'Подготовка и обработка продуктов',
          system_name: '',
          link: '',
          children: [
            {
              id: 532,
              title: 'Кухонные комбайны',
              system_name: 'food-processor',
              link: '/catalog/food-processor/',
            },
            {
              id: 531,
              title: 'Мясорубки',
              system_name: 'mincer',
              link: '/catalog/mincer/',
            },
            {
              id: 507,
              title: 'Блендеры',
              system_name: 'blender',
              link: '/catalog/blender',
            },
            {
              id: 548,
              title: 'Миксеры',
              system_name: 'mixer',
              link: '/catalog/mixer',
            },
            {
              id: 530,
              title: 'Соковыжималки',
              system_name: 'juicer',
              link: '/catalog/juicer/',
            },
            {
              id: 533,
              title: 'Сушилки для овощей и фруктов',
              system_name: 'fruit-veg-dryer',
              link: '/catalog/fruit-veg-dryer/',
            },
            {
              id: 554,
              title: 'Кухонные весы',
              system_name: 'kitchen-scales',
              link: '/catalog/kitchen-scales/',
            },
          ],
        },
        {
          id: 0,
          title: 'Уход за волосами и телом',
          system_name: '',
          link: '',
          children: [
            {
              id: 546,
              title: 'Фены',
              system_name: 'hairdryer',
              link: '/catalog/hairdryer/',
            },
            {
              id: 465,
              title: 'Электробритвы',
              system_name: 'shaver',
              link: '/catalog/shaver/',
            },
            {
              id: 558,
              title: 'Зубные щетки',
              system_name: 'toothbrush',
              link: '/catalog/toothbrush/',
            },
          ],
        },
      ],
    },
    {
      id: 0,
      title: 'Авто и мото',
      system_name: '',
      link: '',
      children: [
        {
          id: 660,
          title: 'Мотоблоки',
          system_name: 'walk-behind-tractor',
          link: '/catalog/walk-behind-tractor/',
        },
        {
          id: 693,
          title: 'Электровелосипеды',
          system_name: 'electric-bike',
          link: '/catalog/electric-bike/',
        },
        {
          id: 705,
          title: 'Электросамокаты',
          system_name: 'electric-scooter',
          link: '/catalog/electric-scooter/',
        },
        {
          id: 693,
          title: 'Электровелосипеды',
          system_name: 'electric-bike',
          link: '/catalog/electric-bike/',
        },
        {
          id: 474,
          title: 'Гироскутеры',
          system_name: 'self-balancing-scooter',
          link: '/catalog/self-balancing-scooter/',
        },
        {
          id: 0,
          title: 'Автоэлектроника',
          system_name: '',
          link: '',
          children: [
            {
              id: 184,
              title: 'Навигаторы',
              system_name: 'navigator',
              link: '/catalog/navigator/',
            },
            {
              id: 144,
              title: 'Видеорегистраторы',
              system_name: 'dashcam',
              link: '/catalog/dashcam/',
            },
            {
              id: 490,
              title: 'Радар детекторы',
              system_name: 'radar-detector',
              link: '/catalog/radar-detector/',
            },
            {
              id: 540,
              title: 'Автомагнитолы',
              system_name: 'car-radio',
              link: '/catalog/car-radio/',
            },
            {
              id: 552,
              title: 'Автомобильные держатели',
              system_name: 'car-holder',
              link: '/catalog/car-holder/',
            },
            {
              id: 582,
              title: 'FM модуляторы',
              system_name: 'fm-modulator',
              link: '/catalog/fm-modulator/',
            },
          ],
        },
      ],
    },
    {
      id: 0,
      title: 'Дом и сад',
      system_name: '',
      link: '',
      children: [
        {
          id: 568,
          title: 'Наборы инструментов',
          system_name: 'set-of-tools',
          link: '/catalog/set-of-tools/',
        },
        {
          id: 0,
          title: 'Саундбары и домашние кинотеатры ',
          system_name: '',
          link: '',
          children: [
            {
              id: 592,
              title: 'Саундбары',
              system_name: 'sound-bar',
              link: '/catalog/set-of-tools/',
            },
          ],
        },
        {
          id: 555,
          title: 'Напольные весы',
          system_name: 'scales',
          link: '/catalog/scales/',
        },
        {
          id: 571,
          title: 'Кронштейны',
          system_name: 'bracket',
          link: '/catalog/bracket/',
        },
        {
          id: 580,
          title: 'Настольные лампы',
          system_name: 'lamp',
          link: '/catalog/lamp/',
        },
      ],
    },
    {
      id: 0,
      title: 'Работа и офис',
      system_name: '',
      link: '',
      children: [
        {
          id: 0,
          title: 'Офисная мебель',
          system_name: '',
          link: '',
          children: [
            {
              id: 493,
              title: 'Кресла',
              system_name: 'armchair',
              link: '/catalog/armchair/',
            },
            {
              id: 526,
              title: 'Стулья',
              system_name: 'chair',
              link: '/catalog/chair/',
            },
          ],
        },
        {
          id: 580,
          title: 'Настольные лампы',
          system_name: 'lamp',
          link: '/catalog/lamp/',
        },
      ],
    },
    {
      id: 0,
      title: 'Услуги мини-экскаватора',
      system_name: '',
      link: '',
      children: [
        {
          id: 0,
          title: 'Обустройство канализации',
          system_name: '',
          link: '',
        },
        {
          id: 0,
          title: 'Септики',
          system_name: '',
          link: '',
        },
        {
          id: 0,
          title: 'Траншеи под фундамент, воду, газ, кабель',
          system_name: '',
          link: '',
        },
        {
          id: 0,
          title: 'Бурение отверстий под сваи',
          system_name: '',
          link: '',
        },
      ],
    },
    {
      id: 0,
      title: 'Услуги манипулятора',
      system_name: '',
      link: '',
      children: [
        {
          id: 0,
          title: 'Доставка блоков, тротуарной плитки, пиломатериалов',
          system_name: '',
          link: '',
        },
      ],
    },
    {
      id: 0,
      title: 'Сервис',
      system_name: '',
      link: '',
      children: [
        {
          id: 0,
          title: 'Ремонт телевизоров',
          system_name: '',
          link: '',
        },
        {
          id: 0,
          title: 'Ремонт мобильных телефонов',
          system_name: '',
          link: '',
        },
      ],
    },
  ],
};

export const uiReducer: Reducer<UiState, UiAction> = (state = uiInitialState, action) => {
  switch (action.type) {
    case UiActionTypes.TOGGLE_SIDEBAR:
      return (() => {
        return {
          ...state,
          isSidebarOpen: !state.isSidebarOpen,
          isUserMenuOpen: false,
          isListingMenuOpen: false,
        };
      })();

    case UiActionTypes.TOGGLE_USER_MENU:
      return (() => {
        return {
          ...state,
          isSidebarOpen: false,
          isUserMenuOpen: !state.isUserMenuOpen,
          isListingMenuOpen: false,
        };
      })();

    case UiActionTypes.TOGGLE_LISTING_MENU:
      return (() => {
        return {
          ...state,
          isSidebarOpen: false,
          isUserMenuOpen: false,
          isListingMenuOpen: !state.isListingMenuOpen,
        };
      })();

    case UiActionTypes.TOGGLE_MENU:
      return (() => {
        return {
          ...state,
          isMenuOpen: action.payload.isOpen !== undefined ? action.payload.isOpen : !state.isMenuOpen,
        };
      })();

    default:
      return state;
  }
};

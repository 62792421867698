const settings = {
  name: 'Каримофф',
  phone: '+375297175804',
  shopAddress: 'г. Лепель, ул. М. Горького 55',
  shopWorkTime: 'пн-пт 09:00-18:00, сб-вс 09:00-14:00',
  shopPhone: '+375333950107',
  email: 'c6470@gmail.com',
  instagram: 'anycompby',
  deliverySelfDiscount: '0',
  deliveryCourier: '0',
  shippingCourierFromH: '9',
  shippingCourierToH: '23',
  shippingCourierOffestH: '2',
  shippingSelfOffestH: '0',
};

export default settings;

export default {
  seoTitle: 'Контакты',
  pageTitle: 'Контакты',
  shopAddress: 'Адрес магазина',
  socials: 'Пишите нам',
  phone: 'Телефон:',
  telegram: 'Telegram:',
  viber: 'Viber:',
  instagram: 'Instagram:',
  email: 'E-mail:',
};
